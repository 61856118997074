import React from "react";
import { Select } from "../../../helpers";
import { getOptions } from "../../../company-search";
import { useSelector } from "react-redux";
import { FilterMainComponentProps } from "../Filter";

const IndustrySelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => {
  const industryOptions = getOptions(
    // @ts-ignore
    useSelector((s) => s.dropdowns.industries),
    "industry_id",
    "industry_name",
    false,
    { label: "All", value: "" },
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      options={industryOptions}
      placeholder={placeholder}
    />
  );
};

export default IndustrySelect;
